<template>
  <div>
    <!-- <Header></Header> -->
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>
        <div class="hotExam flexList">
          <div class="hot-topic-title">
            <img src="@/assets/images/index/hot.png" />
            站内热搜：
          </div>
          <ul>
            <li style="width: 980px; display: inline-block">
              <span v-for="item in hotList" :key="item.sid">
<!--                <span @click="openNews(item.newsId)">{{item.hotTitle}}</span>-->
                <a  :href="item.url" :title="item.hotTitle" target="_blank">{{item.hotTitle}}</a>
              </span>

<!--              <span v-for="item in bannerListBottom" :key="item.bannerId">-->
<!--                <a v-if="item.bannerUrl" :href="item.bannerUrl" target="_blank" style="margin: 0 10px">-->
<!--                  <img :src="FILE_URL + item.imageUrl" style="width: 200px; height: 55px; cursor: pointer" /></a>-->
<!--                <span v-else style="margin: 0 10px">-->
<!--                  <img :src="FILE_URL + item.imageUrl" style="width: 200px; height: 55px; cursor: pointer" />-->
<!--                </span>-->
<!--              </span>-->
<!--              -->
<!--              -->
<!--              <a href="" title="二级造价" target="_blank">二级造价</a>-->
<!--              <a href="" title="公安辅警招聘考试" target="_blank">公安辅警招聘考试</a>-->
<!--              <a href="" title="" target="_blank"></a>-->
<!--              <a href="" title="事业单位分类考试用书" target="_blank">事业单位分类考试用书</a>-->
<!--              <a href="" title="证书领取" target="_blank">证书领取</a>-->
<!--              <a href="" title="发票打印" target="_blank">发票打印</a>-->
<!--              <a href="" title="在线客服" target="_blank">在线客服</a>-->
            </li>
          </ul>
        </div>

        <div class="div_IconBox">
          <div class="optor" v-for="(item, i) in iconbox" :key="i">
            <a :href="item.url" target="_blank">
              <img :src="item.imgUrl" />
              <div class="optor-text">{{ item.text }}</div>
            </a>
          </div>
        </div>

        <div class="main-contanier">
          <div class="contanier-left">
            <ul class="h-100 d-flex flex-column justify-content-around">
              <li v-for="(item, i) in leftNav" :key="i" class="contanier-left-li" :class="{
                  active: leftActiveIndex == i,
                }" @click="changeLeftNav(i)">
                <a href="javascript:void(0);" :title="item.name">{{ item.name }}</a>
              </li>
            </ul>
          </div>
          <div class="contanier-center">
            <div class="center-dash">
              <div v-for="(item, i) in newsListTop" :key="i" class="center-title"
                :class="item.isRed == '1' ? 'red-title' : ''" @click="openNews(item.newsId)" :title="item.newsTitle">
                <i class="iconfont icon-xiaofangkuai"></i>{{ item.newsTitle | ellipsis(30) }}
              </div>
            </div>
            <div class="mt-2">
              <div v-for="(item, i) in newsListMain" :key="i" class="center-list-title flexList"
                :class="item.isRed == '1' ? 'red-title' : ''" @click="openNews(item.newsId)">
                <i class="iconfont icon-xiaofangkuai"></i>
                <div class="line1 center-list-name" :title="item.newsTitle">
                  {{ item.newsTitle | ellipsis(30) }}
                </div>
                <div class="center-list-time">{{ item.createTime }}</div>
              </div>
            </div>
            <div style="text-align: right; cursor: pointer" v-if="leftActiveIndex != 0 && newsListMain.length"
              class="blue-font" @click="viewMore()">
              更多>>
            </div>
          </div>
          <div class="container-right">
            <el-carousel :interval="5000" arrow="hover" class="school-banner">
              <el-carousel-item v-for="(item, i) in bannerList" :key="i">
                <div style="position: relative; height: 100%">
                  <a v-if="item.bannerUrl" :href="item.bannerUrl" target="_blank">
                    <img :src="FILE_URL + item.imageUrl" style="height: 100%; width: 100%" />
                  </a>
                  <img :src="FILE_URL + item.imageUrl" style="height: 100%; width: 100%"   v-else/>

                  <div class="banner_font baner_font_position">
                    <a v-if="item.bannerUrl" :href="item.bannerUrl" target="_blank">
                      <div class="b-text line1">{{ item.bannerTitle }}</div>
                    </a>
                    <div v-else class="b-text line1 pointer">
                      {{ item.bannerTitle }}
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>

            <!-- <div class="swiper-container school-banner">
              <div class="swiper-wrapper ">
                <div class="swiper-slide ">

                </div>
                <div class="swiper-slide ">
                  <img src="@/assets/images/index/lunbo2.png" style="width: 100%; height: 296px" />
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div> -->
          </div>
        </div>

        <el-carousel :interval="5000" arrow="hover" class="school-banner banner-mid mb-4">
          <el-carousel-item v-for="(item, i) in bannerListMiddle" :key="i">
            <a v-if="item.bannerUrl" :href="item.bannerUrl" target="_blank">
              <img :src="FILE_URL + item.imageUrl" style="width: 100%; height: 100%" />
            </a>

            <!-- <div class="banner_font ">
              <a v-if="item.bannerUrl" :href="item.bannerUrl" target="_blank">
                <div class="b-text line1">{{item.bannerTitle}}</div>
              </a>
              <div v-else class="b-text line1 pointer">{{item.bannerTitle}}</div>
            </div> -->
          </el-carousel-item>
        </el-carousel>
        <!-- <div class="swiper-container school-banner">
          <div class="swiper-wrapper">
            <div v-for="(item,i) in bannerListMiddle" :key="i" class="swiper-slide">
              <img :src="FILE_URL+item.imageUrl" style="width: 100%; height: 120px" />

              <div class="banner_font">
                <a v-if="item.bannerUrl" :href="item.bannerUrl" target="_blank">
                  <div class="b-text line1">{{item.bannerTitle}}</div>
                </a>
                <div v-else class="b-text line1 pointer">{{item.bannerTitle}}</div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div> -->

        <!--        <div class="center-img"> <img src="@/assets/images/index/center.png"></div>-->

<!--        这里用到了重定向-->
       <!-- <router-view></router-view>-->
<!--end-->
        <Apply v-if="arrList" :arrList="arrList"></Apply>

        <!-- <div class="div_ExamList com_Box">
          <div class="Tit">
            <div class="Tit-title">
              近期报名
            </div>
            <a href="javascript:void(0);" @click="moreExam()" class="float-right" style="color:#3877bd">查看更多>></a>
          </div>
          <div class="List">
            <div v-for="(item, i) in examList" :key="i" class="flexList div_enrollList" @click="goToLogin">
              <div class="div_enrollList_tag" :class="item.isOpen == 'true' ? 'tag-success' : 'tag-danger'" style="display: flex; align-items: center; justify-content: center;"><span
                  class="enrollList-text">{{item.openMessage}}</span></div>
              <div class=" div_enrollList_center line1">{{item.ksmc}}</div>
              <div class="div_ExamList_time"><i
                  class="iconfont icon-shijian mr-2"></i>报名时间：{{item.bmkssj}}至{{item.bmjssj}}</div>
              <div class="div_ExamList_button  ExamList_button_info"><span
                  class="enrollList-text">{{item.openMessage}}</span></div>
            </div>
          </div>
        </div> -->
        <div class="div_Website com_Box">
          <div class="Tit">
            <div class="Tit-title">重要链接</div>
          </div>
          <div class="img-box" style="gap: 10px">
            <span v-for="item in bannerListBottom" :key="item.bannerId" :style="{ width: widthNum+'%' }">
              <a v-if="item.bannerUrl" :href="item.bannerUrl" target="_blank" >
                <img :src="FILE_URL + item.imageUrl" class="bottom-img" /></a>
              <span v-else >
                <img :src="FILE_URL + item.imageUrl" class="bottom-img"  />
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import "swiper/css/swiper.min.css";
  import Header from "./index-header.vue";
  import Footer from "./index-footer.vue";
  import Navbar from "./navbar.vue";
  import Apply from "./apply.vue";
  import {
    getNewsListPage,
    getBanner,
    getExam,
    getIndex,
  } from "@/api/web/web.js";

  export default {
    name: "index",
    data() {
      var currentDate = new Date();
      let yy = currentDate.getFullYear();
      let mm =
        currentDate.getMonth() + 1 < 10 ?
        "0" + (currentDate.getMonth() + 1) :
        currentDate.getMonth() + 1;
      let dd = currentDate.getDate();
      let hh = currentDate.getHours();
      let mf =
        currentDate.getMinutes() < 10 ?
        "0" + currentDate.getMinutes() :
        currentDate.getMinutes();
      let ss =
        currentDate.getSeconds() < 10 ?
        "0" + currentDate.getSeconds() :
        currentDate.getSeconds();

      return {
        iconbox: [{
            imgUrl: require("@/assets/images/index/icon-computer.png"),
            text: "网上报名",
            url: "/examList",
          },
          {
            imgUrl: require("@/assets/images/index/icon-card.png"),
            text: "打印准考证",
            url: "/ticketList",
          },
          {
            imgUrl: require("@/assets/images/index/icon-search.png"),
            text: "成绩查询",
            url: "/index/scoreList",
            // url: "/login?from=scoreQuery",

          },
          {
            imgUrl: require("@/assets/images/index/icon-search2.png"),
            text: "成绩复核申请",
            url: "/login?from=recheckScore",
          },
          {
            imgUrl: require("@/assets/images/index/icon-send.png"),
            text: "证书邮寄",
            url: "/login?from=certificate",
          },
          // {
          //   imgUrl: require("@/assets/images/index/icon-question.png"),
          //   text: "常见疑问",
          //   url: "/questionList",
          // },
          // {
          //   imgUrl: require("@/assets/images/index/icon-question2.png"),
          //   text: "微信公众号",
          //   url: "/common?jsonp=json_wxgzh",
          // },
          {
            imgUrl: require("@/assets/images/index/icon-kefu.png"),
            text: "在线咨询",
            url: "https://qiyukf.com/client?k=6917d055768be0ce4103dee440d44b60",
          },
          {
            imgUrl: require("@/assets/images/index/icon-img.png"),
            text: "照片工具",
            url: "https://ersfile.jdpta.com/public/util/照片审核处理工具.rar",
          },
        ],
        leftNav: [
          //   {
          //   name: "最新通知公告",
          //   bigTypeId: "0E4BF4D36E232918"
          // }
          // ,
          // {
          //   name: "公务员考试 ",
          //   bigTypeId: "FDCBC5864350530D"
          // },
          // {
          //   name: "事业单位招考",
          //   bigTypeId: "D523326EB3917BFB"
          // },
          // {
          //   name: " 职业资格考试",
          //   bigTypeId: "4B04AF02FCBBBBF9"
          // },
          // {
          //   name: "社会化考试",
          //   bigTypeId: "62F3B43F24ACB54B"
          // },
          // {
          //   name: "企业招聘",
          //   bigTypeId: "86EA6DF3E9D79241"
          // },
        ],
        newsListTop: [],
        newsListMain: [],
        leftActiveIndex: 0,
        bannerList: [], // 上部轮播
        bannerListMiddle: [], // 中部
        bannerListBottom: [], // 底部
        examList: [],
        arrList:[],
        currentDate: yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss,
        hotList: [],
        widthNum:""
      };
    },
    components: {
      Header,
      Navbar,
      Footer,
      Apply
    },
    methods: {
      /**
       * 聚合接口 第一次获取数据
       */
      getIndex(params) {
        getIndex(params).then((res) => {
          if (res.status) {
            // 最新新闻
            let lastNewsList = res.data.lastNewsList;
            let listTop = [];
            let listMain = [];
            if (lastNewsList != null && lastNewsList.length > 0) {
              if (lastNewsList.length >= 2) {
                listTop.push(lastNewsList[0]);
                listTop.push(lastNewsList[1]);
                for (var i = 2; i < lastNewsList.length; i++) {
                  lastNewsList[i].createTime = lastNewsList[i].createTime
                    .substring(0, 10)
                    .replaceAll("-", "/");
                  listMain.push(lastNewsList[i]);
                }
              } else {
                listTop.push(lastNewsList);
              }
            }
            this.newsListTop = listTop;
            this.newsListMain = listMain;

            // banner
            let banner = res.data.bannerList;
            let bannerList = [];
            let bannerListMiddle = [];
            let bannerListBottom = [];
            if (null != banner && banner.length > 0) {
              banner.forEach((item) => {
                if (item.bannerType == "index上部") {
                  bannerList.push(item);
                }
                if (item.bannerType == "index中部") {
                  bannerListMiddle.push(item);
                }
                if (item.bannerType == "index底部") {
                  bannerListBottom.push(item);
                }
              });
            }
            this.bannerList = bannerList;
            this.bannerListMiddle = bannerListMiddle;
            this.bannerListBottom = bannerListBottom;

            // 二级分类
            let smallTypeList = res.data.smallTypeList;
            if (null != smallTypeList && smallTypeList.length > 0) {
              smallTypeList.forEach((item) => {
                this.leftNav.push({
                  name: item.smallTypeName,
                  smallTypeId: item.smallTypeId,
                  bigTypeId: item.bigTypeId,
                });
              });
            }

            // 考试
            let examList = res.data.examList;
            this.arrList=examList
            examList.forEach((item) => {
              if (item.bmkssj > this.currentDate) {
                this.$set(item, "isOpen", "false");
                this.$set(item, "openMessage", "尚未开始");
              } else if (
                item.bmkssj < this.currentDate &&
                item.bmjssj > this.currentDate
              ) {
                this.$set(item, "isOpen", "true");
                this.$set(item, "openMessage", "正在报名");
              } else {
                this.$set(item, "isOpen", "false");
                this.$set(item, "openMessage", "报名结束");
              }
              item.bmkssj =
                item.bmkssj != null ? item.bmkssj.substring(0, 16) : "";
              item.bmjssj =
                item.bmjssj != null ? item.bmjssj.substring(0, 16) : "";
            });
            this.examList = examList;
            this.changeLeftNav(0);

            // 热搜
            this.hotList = res.data.hotList
            this.computedWidth()
          }

        });
      },

      /**
       * 获取新闻
       * @param bigTypeId
       */
      getNewsList(params) {
        getNewsListPage(params).then((res) => {
          if (res.status) {
            let list = res.data;
            let listTop = [];
            let listMain = [];
            if (list.length >= 2) {
              listTop.push(list[0]);
              listTop.push(list[1]);
              for (var i = 2; i < list.length; i++) {
                list[i].createTime = list[i].createTime
                  .substring(0, 10)
                  .replaceAll("-", "/");
                listMain.push(list[i]);
              }
            } else {
              listTop = list;
            }

            this.newsListTop = listTop;
            this.newsListMain = listMain;
          }
        });
      },
      /**
       * 左侧导航 切换新闻
       * */
      changeLeftNav(i) {
        let navList = this.leftNav;
        this.leftActiveIndex = i;
        if (i == 0) {
          this.getNewsList({
            bigTypeId: navList[i].bigTypeId,
            limit: 9,
          });
        } else {
          this.getNewsList({
            smallTypeId: navList[i].smallTypeId,
            limit: 9,
          });
        }
      },
      // 中间更多新闻
      viewMore() {
        console.log(this.newsListMain, this.newsListMain[0].smallTypeId);

        let smallTypeId = this.newsListMain[0].smallTypeId;
        if (smallTypeId) {
          let routeUrl = this.$router.resolve({
            path: "/newsList",
            query: {
              smallTypeId: smallTypeId,
            },
          });
          window.open(routeUrl.href, "_blank");
        }
      },
      /**
       * 打开新闻详情
       * */
      openNews(newsId) {
        let routeUrl = this.$router.resolve({
          path: "/newsDetails",
          query: {
            newsId: newsId,
          },
        });
        window.open(routeUrl.href, "_blank");
      },
      /**
       * 打开banner链接
       * */
      openBanner(url) {
        window.open(url, "_blank");
      },
      /**
       * 获取轮播
       */
      getBannerList() {
        getBanner().then((res) => {
          if (res.status) {
            let list = res.data;
            let bannerList = [];
            let bannerListMiddle = [];
            let bannerListBottom = [];
            if (null != list && list.length > 0) {
              list.forEach((item) => {
                if (item.bannerType == "index上部") {
                  bannerList.push(item);
                }
                if (item.bannerType == "index中部") {
                  bannerListMiddle.push(item);
                }
                if (item.bannerType == "index底部") {
                  bannerListBottom.push(item);
                }
              });
            }
            this.bannerList = bannerList;
            this.bannerListMiddle = bannerListMiddle;
            this.bannerListBottom = bannerListBottom;
          }
        });
      },
      //获取底部轮播图宽度占比
      computedWidth() {
        this.$nextTick(() => {
          let length=this.bannerListBottom.length
          if(length!=0){
            this.widthNum=Number(100/length).toFixed(2)
          }
          // console.log(this.widthNum);
        });
      },

      /**
       * 获取考试报名
       */
      getEaxmList() {
        getExam({
          limit: 6,
        }).then((res) => {
          if (res.status) {
            let list = res.data;
            list.forEach((item) => {
              if (
                item.bmkssj < this.currentDate &&
                item.bmjssj > this.currentDate
              ) {
                this.$set(item, "isOpen", "true");
              } else {
                this.$set(item, "isOpen", "false");
              }
              item.bmkssj =
                item.bmkssj != null ? item.bmkssj.substring(0, 16) : "";
              item.bmjssj =
                item.bmjssj != null ? item.bmjssj.substring(0, 16) : "";
            });
            this.examList = list;
          }
        });
      },

      /**
       * 更多考试
       */
      moreExam() {
        window.location.href = "/examList";
      },
      /**
       * 跳转登录
       */
      goToLogin() {
        window.location.href = "/login";
      },
    },
    filters: {
      /**
       * 兼容ie的多行超出显示省略号
       * value:需要截取的字符串
       * num:需要截取的长度
       */
      ellipsis(value, num) {
        if (value.length > num) {
          return value.substring(0, num) + "...";
        } else {
          return value;
        }
      },
    },
    mounted() {
      // let navList = this.leftNav;
      // this.getNewsList({bigTypeId: navList[0].bigTypeId, limit: 9})
      // this.getBannerList()
      // this.getEaxmList()

      this.getIndex({
        indexName: "tzgg",
      });
    },
  };
</script>

<style>
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/index.css";
</style>

<style scoped>
  .baner_font_position {
    width: 100%;
    color: #fff;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  /*body *{*/
  /*  filter: grayscale(100%);*/
  /*  filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);*/
  /*}*/
.bottom-img{
  max-width:200px;height: 55px; cursor: pointer;
  width: 100%;
}
</style>
